import { useAuthStore } from '@/Stores/Auth'
import { useNotificationsStore } from '@/Stores/Notifications'
import { reactive } from 'vue'


export function useCreatePatentSearch(payload, options = {}) {
    return axios.post('/searches', payload)
        .then(response => {
            if (typeof options.onSuccess === 'function') {
                options.onSuccess(response.data)
            }
        })
        .catch(error => {
            const notifications = useNotificationsStore()

            notifications.error({
                title: 'Failed to create search',
                message: error?.data?.exception ?? 'Please contact support@minesoft.com',
                timeout: 20000,
            })

            if (typeof options.onError === 'function') {
                options.onError(error)
            }
        })
}


export function usePatentSearchRequest() {
    const auth = useAuthStore()

    return reactive({
        /**
         * The default parameters that we will send to the backend
         */
        params: {
            q: null,
            start: 0,
            rows: auth.user?.preferences?.rows_per_page ?? 10,
            sort: auth.user?.preferences?.sort_order ?? 'score desc,publicationdate desc',
            grouped_by: auth.user?.preferences?.patent_grouping ?? 'simplefamily',
            fl: '',
            fq: [],
        },

        /**
         * Managing highlighting
         */
        highlighting: {
            on: auth.user?.preferences?.highlighting_setting?.on ?? false,
            scheme: auth.user?.preferences?.highlighting_setting?.scheme ?? null,
            query: null,
            max_snippets: auth.user?.preferences?.max_snippets ?? 3,
            max_snippet_length: auth.user?.preferences?.max_snippet_length ?? 150,
            ai: false
        },

        /**
         * The object containing the search filters, if any. Object is structured like so:
         * {
         *     assignees_name_probable: ['Siemens', 'Samsung'],
         *     patentstatus: ['Active']
         * }
         */
        filters: {},

        /**
         * The object containing fields and values to exclude from search, if any. Object is structured like so:
         * {
         *     assignees_name_probable: ['Siemens', 'Samsung'],
         *     patentstatus: ['Active']
         * }
         */
        filtersToExclude: {},

        /**
         * The filters that we want to exclude when constructing the filter string (folder filters)
         */
        excludeFilters: ['ratings', 'labels'],

        /**
         * Determine if there are any filters applied
         */
        get hasFilters() {
            if(Object.prototype.hasOwnProperty.call(this.filters, 'filtersToApply')) {
                if(Object.keys(this.filters.filtersToApply).length > 0) {
                    return true
                }
            } else if(Object.prototype.hasOwnProperty.call(this.filters, 'filtersToExclude')) {
                if(Object.keys(this.filters.filtersToExclude).length > 0) {
                    return true
                }
            }

            return Object.values(this.filters).some(item => item.length > 0)
        },

        /**
         * Get the current search filters as a string
         */
        get filterQuery() {
            let filters = []
            let filtersToApply = this.filters.hasOwnProperty('filtersToApply') ? this.filters.filtersToApply : {}
            let filtersToExclude = this.filters.hasOwnProperty('filtersToExclude') ? this.filters.filtersToExclude : {}
            let fq = ''
            let fields = []
            let includeKeys = Object.keys(filtersToApply)
            let excludeKeys = Object.keys(filtersToExclude)
            fields = includeKeys.concat(excludeKeys)
            // Loop through the filters
            fields.filter(field => {
                    // Make sure that we don't post folder filters and that filters exist
                    return !this.excludeFilters.includes(field) && (filtersToApply[field] || filtersToExclude[field])
                })
                .forEach(field => {
                    let values = filtersToApply[field] ?? []

                    // if a date field, we need to use a different format. We only need to use the lowest value
                    // provided, as we are using >= statements.
                    if (['applicationdate', 'publicationdate', 'earliestprioritydate'].includes(field)) {
                        if(filtersToApply[field][0].includes(':')) {
                            let dateRange = filtersToApply[field][0].split(':')

                            if(dateRange[0] !== '') {
                                filters.push(`${field}>=${dateRange[0].replaceAll('-', '')}`)
                            }

                            if(dateRange[1] !== '') {
                                filters.push(`${field}<=${dateRange[1].replaceAll('-', '')}`)
                            } else {
                                let currentDate = new Date();
                                filters.push(`${field}<=${currentDate.getFullYear()}${String(currentDate.getMonth() + 1).padStart(2, '0')}${String(currentDate.getDate()).padStart(2, '0')}`)
                            }
                        } else {
                            if (filtersToApply[field]) {
                                filters.push(`${field}>=${Math.min(...filtersToApply[field])}`)
                            }

                            if (filtersToExclude[field]) {
                                filters.push(`${field}<=${Math.min(...filtersToExclude[field])}`)
                            }
                        }

                    } else if(field === 'raw') {
                        filters.push(values)
                    } else if(field === 'expectedexpirydate') {
                        values.forEach(value => {
                            if (value === 'Dead') {
                                filters.push(`${field}<NOW`)
                            } else {
                                filters.push(`${field}>=NOW`)
                            }
                        })
                    } else if(field === 'grant') {
                        values.forEach(value => {
                            if (value === 'Granted') {
                                filters.push(`grant=yes`)
                            }
                        })
                    } else if(field === 'pending') {
                        values.forEach(value => {
                            if (value === 'Pending') {
                                filters.push(`pending=yes`)
                            }
                        })
                    }
                    // else, map filters into quotation marks and join together with OR
                    else {
                        let filterString = ''

                        if(filtersToApply[field]) {
                            values = values.map(val => `"${ val }"`)
                            filterString += `${field}=(${values.join(' OR ')}`
                            filterString += ')'
                        }

                        if(filtersToExclude[field]) {
                            values = filtersToExclude[field].map(val => `"${ val }"`)
                            if(filterString.length === 0) {
                                filterString += `deleted=false NOT ${field}=(${values.join(' OR ')}`
                            } else {
                                filterString += ` NOT ${field}=(${values.join(' OR ')}`
                            }

                            filterString += ')'
                        }

                        filters.push(filterString)
                    }
            })

            if(Object.keys(filtersToApply).length > 0 || Object.keys(filtersToExclude).length > 0) {
                fq += filters.join(' AND ')
            }

            filters = []

            // if any filters are present, we need to specify to the backend that this is in patbase format.
            if (fq.length > 0) {
                return `{!patbase}${ fq }`
            }

            return ''
        },

        /**
         * Get the filter query without {!patbase} flag
         */
        get rawFilterQuery() {
            return this.filterQuery.replace('{!patbase}', '')
        },

        /**
         * Get the filter count
         */
        get filterCount() {

            let count = 0

            if(this.filters.hasOwnProperty('filtersToApply')) {
                Object.keys(this.filters.filtersToApply).forEach(key => {
                    if (key !== 'raw') {
                        count += this.filters.filtersToApply[key].length
                    } else if (key === 'raw' && this.filters.filtersToApply[key].length) {
                        count++
                    }
                })
            }

            if(this.filters.hasOwnProperty('filtersToExclude')) {
                Object.keys(this.filters.filtersToExclude).forEach(key => {
                    if (key !== 'raw') {
                        count += this.filters.filtersToExclude[key].length
                    } else if (key === 'raw' && this.filters.filtersToExclude[key].length) {
                        count++
                    }
                })
            }

            return count
        },

        /**
         * Get the filters to send to the backend
         */
        get filterOptions() {
            let filterQuery = this.filterQuery
            let options = {}

            // merge the filters with the existing fq params, if any
            if (filterQuery.length) {
                let currentFq = this.params.fq ?? []

                options = {
                    fq: [...currentFq, filterQuery]
                }
            }

            // Merge any folder filters
            options = { ...options, ...this.folderFilterOptions }

            return options
        },

        /**
         * Get the folder filter options to send to the backend
         */
        get folderFilterOptions() {
            let filtersToApply = this.filters.hasOwnProperty('filtersToApply') ? this.filters.filtersToApply : {}

            let folderFilters = {}

            Object.keys(filtersToApply)
                .filter(key => this.excludeFilters.includes(key))
                .forEach(key => folderFilters[key] = filtersToApply[key])

            if (folderFilters !== {}) {
                return { filters: folderFilters }
            }

            return {}
        },

        /**
         * Get the request that we will send to the backend
         */
        get payload() {
            return {
                ...this.params,
                ...this.filterOptions,
                highlighting: this.highlighting
            }
        },

        /**
         * Request that will be sent to backend (some params are filtered out) as they are not needed
         * for the new endpoints
         * will (probably) rename it to payload at some point
         */
        get filteredPayload() {

            return  {
                q: this.params.q,
                grouped_by: this.params.grouped_by,
                rows: this.params.rows,
                start: this.params.start ?? 0,
                highlighting: this.highlighting,
                sort: this.params.sort,
                ...this.filterOptions
            }
        },

        /**
         * Request sent to the backend for public searches (alert run results and published search)
         */
        get publicPayload() {
            return {
                grouped_by: this.params.grouped_by,
                rows: this.params.rows,
                start: this.params.start ?? 0,
                highlighting: this.highlighting,
                sort: this.params.sort,
                ...this.filterOptions
            }
        },

        /*
         * Request sent to the backend when only highlight is relevant (i.e. for most browse tabs)
         */
        get highlightOnlyPayload() {
            return {
                highlighting: this.highlighting
            }
        },

        get facetPayload() {
            return {
                q: this.params.q,
                ...this.filterOptions,
            }
        },

        /**
         * Merge parameters into the params object
         */
        mergeParams(params) {
            this.params = {
                ...this.params,
                ...params,
            }
        },

        /**
         * Paginate to a given page
         */
        updatePage(page) {
            this.updateStart((page*this.params.rows)-this.params.rows)
        },

        /**
         * Update the sort method
         */
        updateSort(sort) {
            this.params.sort = sort
            this.params.start = 0
        },

        /**
         * Update the 'start' value
         */
        updateStart(start) {
            this.params.start = start
        },

        /**
         * Update the number of rows to return
         */
        updateRows(rows) {
            this.params.rows = rows
            this.params.start = 0
        },

        /**
         * Update the grouping method
         */
        updateGrouping(grouping) {
            this.params.grouped_by = grouping
            this.params.start = 0
        },

        /**
         * Update the fields we want to fetch
         */
        updateFields(fields) {
            if (Array.isArray(fields)) {
                fields = fields.join(',')
            }
            this.params.fl = fields
        },

        /**
         * Update highlighting from a given config object
         */
        updateHighlighting(config) {
            if (typeof config !== 'object') return

            this.highlighting.on = config.on ?? false
            this.highlighting.scheme = config.scheme ?? null
            this.highlighting.query = config.query ?? this.highlighting.query
        },

        /**
         * Update the search filters
         */
        updateFilters(filters) {
            this.filters = filters
            this.params.start = 0
        },

        /**
         * Set state from a given object.
         */
        fillFromState(state, fields) {
            this.updateSort(state.sort ?? 'score desc,publicationdate desc')
            this.updateGrouping(state.grouping ?? 'none')
            this.updateFilters(state.filters ?? {}, false)
            this.updateHighlighting(state.highlighting ?? { on: true, scheme: null })
            this.updateSort(state.sort ?? 'score desc,publicationdate desc')
            this.updateFields(fields)
            this.updateRows(state.rows ?? 10)

            // updating "start" is slightly different, as we have to place the user on the
            // appropriate page if they have been browsing patent records. We must
            // calculate the page from the start index, and resolve that to get the start position.
            if (state.start) {
                let rows = this.params.rows
                let page = Math.floor((state.start+rows)/rows)
                this.updateStart((page*rows)-rows)
            }
        }
    })
}


export function usePatentSearchResponse() {
    return reactive({
        /**
         * The response data returned from the backend
         */
        attributes: {},

        /**
         * Check whether the response was successful
         */
        get successful() {
            return !!this.attributes.successful
        },

        /**
         * Check if any results were found
         */
        get hasResults() {
            return this.attributes.numFound > 0
        },

        /**
         * Check if any docs were returned in the response
         */
        get hasDocs() {
            return this.docs?.length > 0
        },

        /**
         * Get the grouping used
         */
        get grouping() {
            return this.attributes.grouping
        },

        /**
         * Get the grouping used as a title
         */
        get groupingTitle() {
            switch (this.grouping) {
                case "simplefamily":
                    return "Minesoft Family Members"
                case "extendedfamily":
                    return "Extended Family Members"
                default:
                    return "Also Published As"
            }
        },

        /**
         * Determine if the response is grouped by family
         */
        get groupedByFamily() {
            return this.grouping.endsWith('family')
        },

        /**
         * The docs returned
         */
        get docs() {
            return this.attributes.docs ?? []
        },

        /**
         * Get the docs returned as an id list
         */
        get idList() {
            return this.docs.map(doc => doc.id)
        },

        /**
         * Get facets from the response, if any
         */
        get facets() {
            return this.attributes.facets
        },

        /**
         * Any errors returned with the response
         */
        get error() {
            return this.attributes.error
        },

        /**
         * The exception returned with the response
         */
        get exception() {
            return this.attributes.exception
        },

        /**
         * The start index of the result set
         */
        get start() {
            return parseInt(this.attributes.start)
        },

        /**
         * The number of rows displaying
         */
        get rows() {
            return parseInt(this.attributes.rows)
        },

        /**
         * The total # of results found
         */
        get total() {
            return parseInt(this.attributes.numFound ?? 0)
        },

        /**
         * The number of items in the current returned items (the current page)
         */
        get item_count() {
            return this.docs.length
        },

        /**
         * The current page, based on data from the response
         */
        get current_page() {
            return (this.start+this.rows)/this.rows
        },

        /**
         * The last possible page, based on data from the response
         */
        get last_page() {
            return Math.ceil(this.total/this.rows)
        },

        /**
         * The start index for the first doc in the current set
         */
        get from() {
            return this.hasResults ? this.start+1 : 0
        },

        /**
         * The index of the last doc in the current set
         */
        get to() {
            return this.hasResults ? this.start+this.item_count : 0
        },

        /**
         * Pagination links for pagination components
         */
        get links() {
            if (this.total > 0) {
                let links = []
                let current_page = this.current_page
                let last_page = this.last_page
                let min = current_page-2
                let max = current_page+3

                for (let n = min; n < max; n++) {
                    if (n > 0 && n <= last_page) {
                        links.push({ label: `${ n }`, active: (n === current_page) })
                    }
                }

                return links
            }
            return []
        },

        /**
         * Check if the previous page exists
         */
        get prev_page_url() {
            return this.current_page > 1
        },

        /**
         * Check if the next page exists
         */
        get next_page_url() {
            return this.current_page+1 <= this.last_page
        },

        /**
         * Determine if a page number exists
         */
        pageExists(page) {
            return page > 0 && page <= this.last_page
        },
    })
}


export function usePatentSearchClient() {
    return reactive({
        /**
         * Whether we're waiting for a response
         */
        processing: false,

        /**
         * A variety of lifecycle hooks that can be injected for convenience
         */
        hooks: {
            beforeRequest: (request) => {},
            onSuccess: (response) => {},
            onError: (error) => {},
        },

        /**
         * Add a lifecycle hook
         */
        hook(name, method) {
            if (this.hooks[name] && typeof method === 'function') {
                this.hooks[name] = method
            }
        },

        /**
         * Perform a search using the search endpoint
         */
        search(endpoint, request, options = {}, publicSearch = false) {
            return this.post(`${publicSearch?'public/':''}search/${endpoint}`, request, options)
        },

        /**
         * Get the specific data for a given record (mainly used for the browse page tabs)
         */
        getRecordData(ucid, endpoint, request = {}, options = {}) {
            return this.post(`${ucid}/${endpoint}`, request, options)
        },

        /**
         * Perform a search using the select endpoint
         */
        select(request, options = {}) {
            return this.post('select', request, options)
        },

        /**
         * Send the request to the backend and get a response
         */
        post(endpoint = 'search', request, options = {}) {
            this.processing = true

            this.hooks.beforeRequest(request)

            axios.post(`/patents/${ endpoint }`, request)
                .then(response => {
                    this.hooks.onSuccess(response.data)
                    typeof options.onSuccess === 'function' && options.onSuccess(response.data)
                })
                .catch(error => {
                    this.hooks.onError(error)
                    typeof options.onError === 'function' && options.onError(error)
                })
                .finally(() => {
                    typeof options.finally === 'function' && options.finally()
                    this.processing = false
                }, options)
        },
    })
}


// Convenience method to spread the request, response and client into vars in one call
// e.g. this can be used like:
// const { request, response, client } = usePatentSearch()
export function usePatentSearch(endpoint = '') {
    return {
        request: usePatentSearchRequest(),
        response: usePatentSearchResponse(),
        client: usePatentSearchClient(),
    }
}
