// Define the symbols here so we can reuse the definition to specify that they should be highlighted within the string scope.
const SYMBOL_DEFINITION = {
    begin: [
        /(?<!\\)[*_?!%~#$^|]/
    ],
    beginScope: {
        1: "symbol"
    }
}

export function useQueryLanguageDefinition() {
    return {
        case_insensitive: true,
        classNameAliases: {
            slot: "string",
            "message-name": "string",
        },
        contains: [
            // String definition
            {
                scope: 'string',
                begin: '"',
                end: '"',
                contains: [
                    SYMBOL_DEFINITION
                ]
            },

            // Fields [field=]
            {
                begin: [
                    new RegExp(`\\b[a-z][a-z0-9_]*\\b`),
                    /(\s+)?(>=?|<=?|=)/
                ],
                beginScope: {
                    1: "field",
                }
            },

            // Citation fields
            {
                begin: [
                    new RegExp(`\\bCT[ABF](_(AP|TP|EX(TP)?|NOV(OBV)?|OBV|(US)?(102(103)?|103)|[ADEILOPRTXY]|CTNF|CTFR))*\\b`),
                    /(\s?)+=?/
                ],
                beginScope: {
                    1: "field"
                }
            },

            // "Magic" commands (i.e. not followed by an = sign)
            {
                begin: ['#list'],
                beginScope: {
                    1: "field",
                }
            },

            // Operators
            {
                begin: [
                    /\s(AND|OR|NEAR|NOT|(NOT\s?)?W(F?\/?\d+|\/?P)|\d+W)\s/,
                ],
                beginScope: {
                    1: 'operator',
                }
            },

            // anyN keyword command
            {
                begin: [
                    /any\d+/,
                    /(\s?)+\(/
                ],
                beginScope: {
                    1: "operator"
                }
            },

            // Truncation symbols
            SYMBOL_DEFINITION
        ],
    }
}
