import { reactive } from 'vue'
import { useAuthStore } from "@/Stores/Auth";


export function useListLayout() {
    return {
        configuration: [],
        aliases: {
            application_date: ['applicationdate'],
            publication_date: ['publicationdate'],
            earliest_priority_date: ['earliestprioritydate'],
            earliest_priority_country: ['earliestprioritycountry'],
            expected_expiry_date: ['expectedexpirydate'],
            patent_status: ['patentstatus', 'expectedexpirydate'],
            current_assignee: ['assignees_name_probable','applicants_name','inventors_name'],
            original_assignee: ['assignees_name_original','applicants_name','inventors_name'],
            agents: ['agents_name'],
            abstract: ['abstracts', 'abstracts_mt'],
            first_claim: ['firstclaims', 'firstclaims_mt'],
            claims_count: ['claimscount'],
            independent_claims_count: ['independentclaimscount'],
            forward_citations_count: ['forwardcitationscount'],
            ipc: ['classifications'],
            cpc: ['classifications'],
            us_class: ['classifications'],
            japanese_f_index: ['classifications'],
            japanese_ft_values: ['classifications'],
            keywords_in_context: ['snippets', 'abstracts', 'abstracts_mt'],
            main_ipc: ['classifications'],
            main_cpc: ['classifications'],
            main_us_class: ['classifications'],
            locarno: ['classifications'],
            thumbnail: ['drawings', 'drawingscount'],
            carousel: ['drawings', 'drawingscount'],
            mosaic: ['drawings', 'drawingscount'],
            document_type: ['type'],
            application_members: ['familydata'],
            simple_family: ['familydata'],
            extended_family: ['familydata'],
            seps: 'seps',
            dynamic: ['familydata'],
        },
        get base_fields() {
            return [
                'id',
                'country',
                'kindcode',
                'score',
                'titles',
                'titles_mt',
                'publicationnumber',
                'applicationnumber',
                'design',
                'srcpat',
                'abexists',
            ]
        },
        get selected_fields() {
            return this.configuration.flat()
        },
        get alias_fields() {
            let aliasFields = []
            let selected = this.selected_fields

            for (let key in this.aliases) {
                if (selected.includes(key)) {
                    aliasFields.push(...new Set([...this.aliases[key]]))
                }
            }

            return aliasFields
        },
        get unqueryable() {
            return [
                'simplefamily',
                'extendedfamily',
                'applicationmembers',
                'dynamic',
                ...Object.keys(this.aliases)
            ]
        },
        get fields() {
            return [
                ...new Set([
                    ...this.base_fields,
                    ...this.selected_fields,
                    ...this.alias_fields
                ].filter(field => !this.unqueryable.includes(field)))
            ]
        },
        get options() {
            return useListLayoutOptions()
        },
        updateConfiguration(configuration) {
            this.configuration = this.validateConfiguration(configuration)
        },
        validateConfiguration(configuration) {
            if (! Array.isArray(configuration)) {
                throw "List configuration must be an Array"
            }

            configuration.forEach((group, index) => {
                configuration[index] = group.filter(item => this.options.hasOwnProperty(item))
            })

            return configuration
        },
    }
}

export function useTableLayout() {
    const selected = [
        'titles',
        'publicationdate',
        'applicationdate',
        'assignees_name_probable',
    ]

    return {
        selected_fields: selected,
        aliases: {
            'dead_or_alive': ['expectedexpirydate'],
            'assignees_name_original': ['assignees_name_original','applicants_name','inventors_name'],
            'assignees_name_probable': ['assignees_name_probable','applicants_name','inventors_name'],
            'simplefamily': ['familydata'],
            'extendedfamily': ['familydata'],
            'applicationmembers': ['familydata'],
            'dynamic': ['familydata'],
        },
        get base_fields() {
            return [
                'id',
                'applicationnumber',
                'country',
                'publicationnumber',
                'kindcode',
                'drawingscount',
                'srcpat',
                'claexists',
                'design',
                'drawings',
                'drawingscount',
            ]
        },
        get unqueryable() {
            return [
                'pinned_folders',
                'user_appraisal',
                'simplefamily',
                'extendedfamily',
                'applicationmembers',
                'dynamic',
                'custom_fields'
            ]
        },

        /*
         * List of fields that can't be included in published searches
         */
        get unPublishable() {
            return ['pinned_folders', 'user_appraisal', 'custom_fields']
        },
        get fields() {
            return [
                ...new Set([
                    ...this.base_fields,
                    ...this.selected_fields,
                    ...this.translation_fields,
                    ...this.alias_fields
                ].filter(field => !this.unqueryable.includes(field)))
            ]
        },
        get translation_fields() {
            let mtFields = []
            let translatable = ['titles', 'abstracts', 'claims', 'firstclaims', 'descriptions']
            translatable.forEach((field) => {
                if (this.selected_fields.includes(field)) {
                    mtFields.push(field + '_mt')
                }
            })
            return mtFields
        },
        get alias_fields() {

            let aliasFields = []

            for (let key in this.aliases) {
                if (this.selected_fields.includes(key)) {
                    aliasFields.push(...new Set([...this.aliases[key]]))
                }
            }

            return aliasFields
        },
        get options() {
            return useTableLayoutOptions()
        },
        updateFields(fields) {
            this.selected_fields = this.validateFields(fields)
        },
        validateFields(fields) {

            if (! Array.isArray(fields)) {
                throw "Table options must be an Array"
            }

            let allowed = Object.keys(this.options)

            let validatedFields = []
            fields.forEach((field) => {
                if (allowed.includes(field)) {
                    validatedFields.push(field)
                }
            })

            return validatedFields
        }
    }
}

export function useListLayoutOptions() {
    return {
        application_number: 'Application Number',
        application_date: 'Application Date',
        publication_date: 'Publication Date',
        earliest_priority_date: 'Priority Date',
        earliest_priority_country: 'Priority Country',
        expected_expiry_date: 'Expected Expiry Date',
        patent_status: 'Patent Status',
        current_assignee: 'Current Assignee',
        original_assignee: 'Original Assignee',
        ultimate_owner: 'Ultimate Owner',
        inventors: 'Inventors',
        agents: 'Agents',
        examiners: 'Examiners',
        abstract: 'Abstract',
        keywords_in_context: 'Keywords in Context',
        claims: 'Claims',
        independent_claims: 'Independent Claims',
        first_claim: 'First Claim',
        claims_count: 'Number of Claims',
        independent_claims_count: 'Number of Independent Claims',
        forward_citations_count: 'Number of Forward Citations',
        ipc: 'IPC Classifications',
        cpc: 'CPC Classifications',
        us_class: 'US Classifications',
        locarno: 'Locarno Classifications',
        japanese_f_index: 'Japanese F-Index',
        japanese_ft_values: 'Japanese F-Terms',
        main_ipc: 'Main IPC',
        main_cpc: 'Main CPC',
        main_us_class: 'Main US',
        thumbnail: 'Thumbnail',
        carousel: 'Carousel',
        mosaic: 'Mosaic Grid',
        document_type: 'Application Type',
        docdesc: 'Document Description',
        fda: 'FDA Data',
        application_members: 'Application Members',
        simple_family: 'Minesoft Family',
        extended_family: 'Extended Family',
        dynamic: 'Dynamic',
        smart_summary: 'Smart Summary',
        claims_summary: 'Claims Summary',
        document_chat: 'Document Chat',
        family_tree: 'Family Tree',
        view_family: 'View Family',
        comments: 'Comments',
        rating: 'Rating',
        labels: 'Labels',
        pdf: 'Original PDF Link',
        familytree: 'Family Tree Link',
        custom_fields_values: 'Custom Fields Values',
        seps: 'Standard Essential Patent declarations',
    }
}

export function useListLayoutOptionGroups() {
    const auth = useAuthStore();

    let defaultOptions = [
        {
            name: 'general',
            title: 'General',
            multiselect: true,
            options: [
                'application_number',
                'application_date',
                'publication_date',
                'earliest_priority_date',
                'earliest_priority_country',
                'expected_expiry_date',
                'patent_status',
                'document_type',
                'docdesc',
                'current_assignee',
                'original_assignee',
                'ultimate_owner',
                'inventors',
                'agents',
                'examiners',
                'claims_count',
                'independent_claims_count',
                'forward_citations_count',
                'familytree',
            ],
        },
        {
            name: 'long_text',
            title: 'Long Text',
            multiselect: true,
            options: ['abstract', 'first_claim', 'keywords_in_context'],
        },
        {
            name: 'classifications',
            title: 'Classifications',
            multiselect: true,
            options: [
                'ipc',
                'cpc',
                'us_class',
                'locarno',
                'japanese_f_index',
                'japanese_ft_values',
                'main_ipc',
                'main_cpc',
                'main_us_class',
            ],
        },
        {
            name: 'images',
            title: 'Images',
            multiselect: false,
            options: ['thumbnail', 'carousel', 'mosaic'],
        },
        {
            name: 'family_members',
            title: 'Family Members',
            multiselect: false,
            options: ['application_members', 'simple_family', 'extended_family', 'dynamic'],
        },
        {
            name: 'tables',
            title: 'Tables',
            multiselect: true,
            options: ['fda', 'seps'],
        },
        {
            name: 'actions',
            title: 'Actions',
            multiselect: true,
            options: ['family_tree', 'view_family'],
        },
    ];

    if (auth.moduleEnabled('generative_ai')) {
        //add summaries to actions
        let actionsIndex = defaultOptions.findIndex(group => group.name === 'actions');

        defaultOptions[actionsIndex].options.push('smart_summary', 'claims_summary');

        if(auth.moduleEnabled('access_third_party_ai')) {
            defaultOptions[actionsIndex].options.push('document_chat');
        }
    }

    return defaultOptions;
}


export function useNonTabularExportOptions(includeFolderData = false) {

    const auth = useAuthStore()

    let options = useListLayoutOptionGroups()
    options = options.filter(group => group.name !== 'actions')

    // Add claims and independent claims to non-tabular exports
    options[options.findIndex(group => group.name === 'long_text')].options.push( 'claims', 'independent_claims');

    if (auth.moduleEnabled('generative_ai')) {
        options[options.findIndex(group => group.name === 'long_text')].options.push('claims_summary');
        options[options.findIndex(group => group.name === 'long_text')].options.push('smart_summary');
    }

    //only thumbnail is available for exports
    options[options.findIndex(group => group.name === 'images')].options = ['thumbnail'];

    if (includeFolderData) {
        let folderOptions = {
            name: 'folder',
            title: 'Folder',
            multiselect: true,
            options: [
                'comments', 'rating', 'labels'
            ]
        }

        options.push(folderOptions)
    }

    options.push({
        name: 'other',
        title: 'Other',
        multiselect: true,
        options: [
            'pdf',
            'custom_fields_values',
        ]
    })

    return options
}

export function useTableLayoutOptions() {
    return {
        applicationnumber: {
            title: 'Application Number',
            width: '190px',
        },
        titles: {
            title: 'Title',
            width: '500px',
        },
        publicationdate: {
            title: 'Publication Date',
            width: '160px',
        },
        applicationdate: {
            title: 'Application Date',
            width: '165px',
        },
        expectedexpirydate: {
            title: 'Expected Expiry Date',
            width: '200px',
        },
        score: {
            title: 'Relevance Score',
            width: '175px',
        },
        pinned_folders: {
            title: 'Pinned Folders',
            width: '350px',
        },
        drawings: {
            title: 'Images',
            width: '250px',
        },
        abstracts: {
            title: 'Abstract',
            width: '500px',
        },
        assignees_name_probable: {
            title: 'Current Assignee(s)',
            width: '280px',
        },
        assignees_name_original: {
            title: 'Original Assignee(s)',
            width: '280px',
        },
        ultimate_owner: {
            title: 'Ultimate Owner',
            width: '280px',
        },
        inventors: {
            title: 'Inventors',
            width: '280px',
        },
        agents_name: {
            title: 'Agents',
            width: '280px',
        },
        firstclaims: {
            title: 'First Claim',
            width: '500px',
        },
        patentstatus: {
            title: 'Patent Status',
            width: '200px',
        },
        claimscount: {
            title: '# of Claims',
            width: '180px',
        },
        independentclaimscount: {
            title: '# of Independent Claims',
            width: '215px',
        },
        earliestprioritydate: {
            title: 'Earliest Priority Date',
            width: '190px',
        },
        earliestprioritycountry: {
            title: 'Earliest Priority Country',
            width: '280px',
        },
        type: {
            title: 'Application Type',
            width: '200px',
        },
        docdesc: {
            title: 'Document Description',
            width: '200px',
        },
        user_appraisal: {
            title: 'Document Appraisal',
            width: '300px',
        },
        custom_fields: {
            title: 'Custom Fields',
            width: '500px',
        },
        dead_or_alive: {
            title: 'Dead or Alive',
            width: '140px',
        },
        pending: {
            title: 'Pending',
            width: '140px',
        },
        simplefamily: {
            title: 'Minesoft Family',
            width: '230px',
        },
        extendedfamily: {
            title: 'Extended Family',
            width: '230px',
        },
        applicationmembers: {
            title: 'Application Members',
            width: '230px',
        },
        forwardcitationscount: {
            title: '# of Forward Citations',
            width: '205px'
        },
        seps: {
            title: 'Standard Essential Patents',
            width: '205px'
        },
    }
}

export function useSplitLayout() {
    return {}
}
