import { defineStore } from 'pinia'
import { ref, computed, markRaw } from 'vue'

export const useDynamicModalStore = defineStore('dynamic_modal', () => {

    // the modal's open state
    const isOpen = computed(() => stack.value.some(modal => modal.isOpen))

    // the modal history stack
    const stack = ref([])

    const updating = ref(false)

    // Open a modal
    const open = (modal, props = {}, dismissible=true) => {
        if (! updating.value) {
            stack.value.push({
                modal: markRaw(modal),
                props: props,
                isOpen: true,
                dismissible: dismissible
            })
        }
    }

    // Close a modal. The setTimeout before mutating the 'stack' ref here is to allow time for the closing animation.
    // The 'isClosing' ref here makes sure no other 'close' events fire during the animation, as this can cause issues.
    const close = (forceClose = false) => {
        if (! updating.value) {
            // Force close will close all open nested modals
            if (forceClose) {
                updating.value = true
                stack.value.forEach(item => item.isOpen = false)
                setTimeout(() => {
                    stack.value = []
                    updating.value = false
                }, 500)
            }

            // Default behaviour is to close only the most recently opened modal
            else if (stack.value[stack.value.length-1]) {
                updating.value = true
                stack.value[stack.value.length-1].isOpen = false
                setTimeout(() => {
                    stack.value.pop()
                    updating.value = false
                }, 500)
            }
        }

    }

    return { isOpen, open, close, stack }
})
