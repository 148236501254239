import '../css/app.css'
import { createApp } from 'vue/dist/vue.esm-bundler'
import { createHead } from '@unhead/vue'
import { createPinia } from 'pinia'
import { i18nVue } from 'laravel-vue-i18n'
import VueTippy from 'vue-tippy'
import axios from 'axios'
import router from './routes.js'
import hljs from 'highlight.js/lib/core'
import { useQueryLanguageDefinition } from '@/query-language-definition'
import { VueMaskDirective } from 'v-mask'


/**
 * Axios configuration
 */
window.axios = axios
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
window.axios.defaults.baseURL = '/api'
window.axios.defaults.withCredentials = true

// When the server responds with a session expired response code, redirect the user to the login page.
window.axios.interceptors.response.use(response => response, (error) => {
    if (
        error?.response?.status === 419 ||
        (
            error?.response?.status === 401 &&
            !error.request.responseURL.endsWith('/api/user')
        )
    ) {
        window.location.href = '/login'
    }
    return Promise.reject(error.response)
})


/**
 * Configure the query language definition in highlightjs for syntax highlighting
 */
window.hljs = hljs
window.hljs.registerLanguage('search', () => useQueryLanguageDefinition())



/**
 * Input masking configuration
 */
// small hack to get v-mask to work in vue3
// from https://github.com/probil/v-mask/issues/498#issuecomment-827027834
const vMaskV2 = VueMaskDirective;
const vMaskV3 = {
    beforeMount: vMaskV2.bind,
    updated: vMaskV2.componentUpdated,
    unmounted: vMaskV2.unbind
};


/**
 * Create app and bootstrap services & modules
 */
const app = createApp({})
    .use(createPinia())
    .use(createHead())
    .use(router)
    /*.use(i18nVue, {
        resolve: lang => import(`../../lang/${lang}.json`)
    })*/
    .use(VueTippy, {
        defaultProps: {
            arrow: false
        }
    })
    .directive('mask', vMaskV3)

app.provide('appName', import.meta.env.VITE_APP_NAME)

// Register a global custom directive called `v-focus`
app.directive('focus', {
    // When the bound element is mounted into the DOM...
    mounted(el) {
        // Focus the element
        el.focus()
    }
})

app.mount('#app')




